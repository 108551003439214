import { cva } from "class-variance-authority";
import { trackInterface } from "~/utils/tracking";
import { $path } from "remix-routes";

export const track = trackInterface("Top Nav");

// Tailwind styling for the primary nav items
export const navItem = cva("h-8 text-base md:text-sm font-medium flex space-x-2 items-center rounded-lg px-3", {
  variants: {
    state: {
      active: "text-contrast",
      inactive: "text-contrast/50 hover:text-contrast",
    },
  },
  defaultVariants: {
    state: "inactive",
  },
});

export const COMPANY = [
  { name: "Careers", href: "https://flipsidecrypto.breezy.hr" },
  { name: "Terms & Privacy", href: "https://flipsidecrypto.xyz/terms" },
] as const;

export const DATA_ACCESS = [
  { title: "Studio", description: "Build queries and dashboards", to: $path("/edit") },
  { title: "API", description: "Access data programmatically", to: $path("/api-keys") },
  { title: "LiveQuery", description: "Query external APIs in real-time", to: $path("/livequery") },
  { title: "Pricing", description: "View our pricing plans", to: $path("/pricing") },
] as const;

export const INSIGHTS = [
  { title: "Verified Insights", to: $path("/insights/verified-insights"), target: "_self" },
  { title: "Dashboards", to: $path("/insights/dashboards"), target: "_self" },
  { title: "Queries", to: $path("/insights/queries"), target: "_self" },
  { title: "Analysts", to: $path("/insights/analysts"), target: "_self" },
  { title: "Teams", to: $path("/insights/teams"), target: "_self" },
  { title: "Research Tools", to: "https://science.flipsidecrypto.xyz/research", target: "_blank" },
] as const;

export const RESOURCES = [
  { title: "Documentation", href: "https://docs.flipsidecrypto.xyz" },
  { title: "Resource Center", href: "https://flipsidecrypto.xyz/fc/resources" },
  { title: "Newsletter", href: "https://flipsidecrypto.xyz/fc/about/newsletter" },
  { title: "Research and Reports", href: "https://flipsidecrypto.xyz/fc/resources?type=Research+%26%C2%A0Reports" },
] as const;

export const EARN = [
  {
    title: "Quests",
    to: (ecosystem?: string) => (ecosystem ? $path("/earn/:ecosystem", { ecosystem }) : $path("/earn")),
    description: "Complete quests to level up on chain",
  },
  {
    title: "Grail",
    to: () => $path("/grail/:ecosystem/:slug", { ecosystem: "near", slug: "s2" }),
    description: "Earn points to win more money",
  },
] as const;
